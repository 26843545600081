import React from "react";
import { PrivacySection } from '../components/PrivacySection';

export const PrivacyPolicy = () => {
    return (
        <>
            {/* Privacy Policy Starts */}
            <PrivacySection title="Information We Collect">
                <p>We may collect information about you in a variety of ways. The information we may collect via the App includes:</p>
                <ul>
                    <li><strong>Personal Data:</strong> Email address, username, and any other information you voluntarily provide when registering with the App or participating in App activities.</li>
                    <li><strong>Device Information:</strong> Device ID, device type, operating system, language preference, country, IP address, and mobile network information.</li>
                    <li><strong>Usage Data:</strong> Information about how you use the App, including time spent, features used, and in-app activities.</li>
                </ul>
            </PrivacySection>

            <PrivacySection title="Use of Your Information">
                <p>We may use the information we collect from you in the following ways:</p>
                <ul>
                    <li>To personalize your experience and deliver relevant content and product offerings.</li>
                    <li>To improve our App to better serve you.</li>
                    <li>To send periodic emails regarding your use of the App or other products and services.</li>
                    <li>To follow up after correspondence (live chat, email, or phone inquiries).</li>
                </ul>
            </PrivacySection>

            <PrivacySection title="Disclosure of Your Information">
                <p>We do not sell, trade, or transfer your Personally Identifiable Information to outside parties unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our App, conducting our business, or serving our users, provided they agree to keep this information confidential.</p>
                <p>We may also release information to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>
            </PrivacySection>

            <PrivacySection title="Data Retention">
                <p>We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by applicable laws or regulations.</p>
            </PrivacySection>

            <PrivacySection title="Security of Your Information">
                <p>We use administrative, technical, and physical security measures to protect your personal information. However, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
            </PrivacySection>

            <PrivacySection title="Your Rights">
                <p>Depending on your location, you may have the following rights with respect to your personal data:</p>
                <ul>
                    <li>The right to access – You have the right to request copies of your personal data.</li>
                    <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate.</li>
                </ul>
            </PrivacySection>
            {/* Privacy Policy Ends */}
        </>
    )
}