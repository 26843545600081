import './App.css';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import Header from './components/Header';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AboutUs } from './pages/AboutUs';
import { ContactUs } from './pages/ContactUs';
import { Game } from './pages/Game';
import { PrivacyPolicy } from './pages/Policy';
import { GameHeader } from './components/GameHeader';
import { SmallNavbar } from './components/SmallNavbar';
import usePersistQueryParams from './hooks/usePersistQueryParams';

function App() {
  const location = useLocation();
  const currentRoute = location.pathname;
  usePersistQueryParams();

  return (
    <main>
      <div className="wrapper">
        {/* Conditional Header Starts */}
        {currentRoute === '/game' && <GameHeader />}
        {currentRoute === '/about' && <SmallNavbar />}
        {currentRoute !== '/game' && currentRoute !== '/about' && currentRoute !== '/policy' && <Header />}
        {/* Conditional Header Ends */}

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/game' element={<Game />} />
          <Route path='/policy' element={<PrivacyPolicy />} />
        </Routes>

        {/* Conditional Footer Starts */}
        {currentRoute !== '/policy' && <Footer />}
        {/* Conditional Footer Ends */}
      </div>
    </main>
  );
}

export default App;