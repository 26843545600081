import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import QRCodeGenerator from './QRCodeGenerator';


export const Footer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get('ref');

  const createLink = (path) => `${path}?${queryParams.toString()}`;

  return (
    <div className="footer-wrapper">
      <footer className="footer">
        <div className="footer-logo">
          <img src="assets/images/logo.png" alt="" />
        </div>
        <nav className="footer-nav py-4">
          <ul>
            <li><Link to={createLink('/')} className='active'>Home</Link></li>
            <li><Link to={createLink('/about')}>About</Link></li>
            {!param && <li><Link to={createLink('/contact')}>Contact</Link></li>}
            <li><Link to={createLink('/terms')}>Terms of Services</Link></li>
            <li><Link to={createLink('/privacy')}>Privacy/Policy</Link></li>
            <li><Link to={createLink('/sitemap')}>Site Map</Link></li>
          </ul>
        </nav>
        <div className="social-images">
          <img src="assets/images/app.png" alt="" className='mb-3' />
          <img src="assets/images/google.png" alt="" className='mb-3' />
        </div>
        <div className="qr-code">
              <QRCodeGenerator />
        </div>
        <div className="copy">
          <span>Copyright(C) 2024 ShibaDragon. All rights reserved.</span>
        </div>
      </footer>
    </div>
  );
};