import React from "react";

export const PrivacySection = ({ title, children }) => {
    return (
        <div className="privacy-section">
            <h2>{title}</h2>
            <div className="privacy-content">
                {children}
            </div>
        </div>
    );
};