import React from 'react'
import { useLocation } from 'react-router-dom';


export const Contact = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const param = queryParams.get('ref');

    if (param) {
        // return empty component
        return (<> <div></div> </>);
    }

    return (
        <>
            <section className="contact_wrapper pt-5">
                <div className="container">
                    <div className="contact-header text-center">
                        <h2>Contact Us</h2>
                        <p className='mt-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque tempora quam voluptate reiciendis? <br /> Officiis tenetur debitis nesciunt neque placeat corporis?</p>
                    </div>
                    <div className="contact-form">
                        <form action="">
                            <div className="row ">
                                <div className="form-group col-md-4 mb-5">
                                    <input type="text" className='form-control' placeholder='First Name' autocomplete="off" />
                                    <i className='fa fa-user'></i>
                                </div>

                                <div className="form-group col-md-4 mb-5">
                                    <input type="text" className='form-control' placeholder='SureName' autocomplete="off" />
                                    <i className='fa fa-user'></i>
                                </div>

                                <div className="form-group col-md-4 mb-5">
                                    <input type="text" className='form-control' placeholder='Email' autocomplete="off" />
                                    <i className='fa fa-envelope'></i>
                                </div>

                                <div className="form-group col-md-12">
                                    <textarea name="" id="" cols="30" rows="10" className='form-control' placeholder='Text Message' autocomplete="off"></textarea>
                                </div>
                            </div>
                            <div className="contact-btn text-center mt-5">
                                <button className='btn border-0'><img src="/assets/images/buttons/contact.png" alt="" /></button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="gol-bucket-left">
                    <img src="/assets/images/gold1.png" width='200' alt="" />
                </div>

                <img src="/assets/images/diamond.png" className='c-diamond-img' width='80' alt="" />

            </section>
        </>
    )
}
